import { useRef } from "react";
import { Box, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface ImageListChooserItem {
  title: string;
  image: string;
  enableInfo: boolean;
}

export enum ImageListChooserSize {
  Small,
  Big,
}

function renderItem(
  item: ImageListChooserItem,
  isBig: boolean,
  isSelected: boolean,
  onSelected: () => void
) {
  const mainGreen = "#00dc9b";
  return (
    <Box>
      <Box
        sx={{
          margin: "2px",
          backgroundColor: isSelected ? mainGreen : "transparent",
          position: "relative",
        }}
      >
        <IconButton
          sx={{ margin: "10px", padding: 0 }}
          disabled={isSelected}
          onClick={() => {
            onSelected();
          }}
        >
          <img src={item.image} alt={item.title} width="100%" loading="lazy" />
          {item.title.length > 0 && (
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                minHeight: "30%",
                backgroundColor: isSelected
                  ? mainGreen
                  : "rgba(65, 64, 66, 0.38)",
                display: "flex",
                justifyContent: isBig ? "left" : "center",
                alignItems: "center",
                paddingLeft: isBig ? "16px" : 0,
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  color: "#fff",
                  textAlign: isBig ? "left" : "center",
                }}
              >
                {item.title}
              </Typography>
            </Box>
          )}
        </IconButton>
      </Box>
    </Box>
  );
}

export default function ImageListChooser(props: {
  size: ImageListChooserSize;
  title: string;
  selectedIndex: number;
  items: ImageListChooserItem[];
  onSelected: (index: number) => void;
  onInfoClick: (index: number) => void;
}) {
  const isBig = props.size === ImageListChooserSize.Big;
  const settings = {
    infinite: false,
    dots: true,
    arrows: false,
    speed: 500,
    rows: isBig ? 2 : 3,
    slidesPerRow: isBig ? 3 : 5,
  };
  const slider = useRef<Slider>(null);
  const hasArrows = props.items.length > settings.slidesPerRow * settings.rows;
  return (
    <Box>
      <Typography
        sx={{
          fontFamily: '"Montserrat", sans-serif',
          fontSize: "1.3em",
          fontWeight: 600,
          color: "#000",
          marginTop: "0.5em",
          marginBottom: "0.5em",
        }}
      >
        {props.title}
      </Typography>
      <Box
        sx={{
          position: "relative",
          padding: "0",
        }}
      >
        {hasArrows && (
          <IconButton
            sx={{
              padding: 0,
              position: "absolute",
              top: "50%",
              left: "30px",
              transform: "translate(-50%, -50%)",
            }}
            onClick={() => {
              if (slider.current === null) {
                console.error();
                return;
              }
              slider.current.slickPrev();
            }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
        )}
        <Slider ref={slider} {...settings}>
          {props.items.map(function (item: any, index: number) {
            const isSelected = index === props.selectedIndex;
            return renderItem(item, isBig, isSelected, () =>
              props.onSelected(index)
            );
          })}
        </Slider>
        {hasArrows && (
          <IconButton
            sx={{
              padding: 0,
              position: "absolute",
              top: "50%",
              right: "22.5px",
              transform: "translate(50%, -50%)",
            }}
            onClick={() => {
              if (slider.current === null) {
                console.error();
                return;
              }
              slider.current.slickNext();
            }}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}
