import { useState, useEffect } from "react";

import { Typography, Box, Dialog, DialogContent, Button } from "@mui/material";

import { MapItem, MapCategory } from "./CommonDataTypes";
import { client } from "./RpcClient";
import { config } from "./Config";

export default function MapNavigation(props: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const [mapData, setMapData] = useState<{
    items: MapItem[];
    categories: MapCategory[];
  }>({ items: [], categories: [] });
  useEffect(() => {
    const getData = async () => {
      const result = await Promise.all([
        fetch(`${config.apiUrl}/items/map_items?fields=*,translations.*`).then(
          (response) => response.json()
        ),
        fetch(
          `${config.apiUrl}/items/map_categories?fields=*,translations.*`
        ).then((response) => response.json()),
      ]);
      setMapData({
        items: result[0].data,
        categories: result[1].data,
      });
    };
    getData();
  }, []);
  const itemsPerCategory: Array<{ category: MapCategory; items: MapItem[] }> =
    [];
  for (let category of mapData.categories) {
    itemsPerCategory.push({ category: category, items: [] });
    for (let item of mapData.items) {
      if (item.map_category_id === category.id) {
        itemsPerCategory[itemsPerCategory.length - 1].items.push(item);
      }
    }
  }
  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={props.open}
      onClose={() => props.setOpen(false)}
    >
      <DialogContent>
        <Button
          sx={{ float: "right" }}
          variant="outlined"
          onClick={() => {
            props.setOpen(false);
          }}
        >
          Zapri
        </Button>
        {itemsPerCategory.map((itemsWithCategory) => (
          <Box>
            <Typography
              sx={{ fontSize: "28px", fontWeight: 600, margin: "30px 0" }}
            >
              {
                itemsWithCategory.category.translations.find(
                  (it) => it.languages_code === "sl-SI"
                )?.title
              }
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                gap: "1rem",
              }}
            >
              {itemsWithCategory.items.map((item) => (
                <Button
                  variant="outlined"
                  key={item.id}
                  onClick={async () => {
                    await client.request("TeleportToTargetPoint", {
                      external_id: item.external_id,
                    });
                    props.setOpen(false);
                  }}
                >
                  <Box
                    sx={{ width: "70%" }}
                    component="img"
                    src={`${config.apiUrl}/assets/${
                      item.translations.find(
                        (it) => it.languages_code === "en-US"
                      )?.image
                    }`}
                  />
                  <Box sx={{ dosplay: "flex" }}>
                    <Typography sx={{ fontSize: "16px" }}>
                      {
                        item.translations.find(
                          (it) => it.languages_code === "en-US"
                        )?.title
                      }
                    </Typography>
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: "12px",
                        textTransform: "none",
                        color: "rgba(0, 0, 0, 0.60)",
                      }}
                    >
                      {
                        item.translations.find(
                          (it) => it.languages_code === "en-US"
                        )?.subtitle
                      }
                    </Typography>
                  </Box>
                </Button>
              ))}
            </Box>
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
}
