import { useState } from "react";

import { useSearchParams } from "react-router-dom";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SyncIcon from "@mui/icons-material/Sync";

import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { config } from "../Config";

interface ReportData {
  s: number;
  failed_elements: string[];
  successful_elements: string[];
  unexact_variations: string[];
  failed_variations: string[];
  successful_variations: string[];
}

function PricesSyncReport(props: { data: ReportData }) {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div>
      {[
        {
          title: "Successfull elements",
          subtitle: "Perfect sync :)",
          data: props.data.successful_elements,
        },
        {
          title: "Successful variations",
          subtitle: "Did sync price and found an exact match",
          data: props.data.successful_variations,
        },
        {
          title: "Variations with warning",
          subtitle: "Did sync price but didn't find exact match",
          data: props.data.unexact_variations,
        },
        {
          title: "Failed elements",
          subtitle: "These didn't sync at all (is the slug correct?)",
          data: props.data.failed_elements,
        },
        {
          title: "Failed variations",
          subtitle: "These variations didn't sync at all (no color id?)",
          data: props.data.failed_variations,
        },
      ].map((accordionEntry, accordionIndex) => {
        const panelId = `panel${accordionIndex}`;
        return (
          <Accordion
            expanded={expanded === panelId}
            onChange={handleChange(panelId)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${panelId}bh-content`}
              id={`${panelId}bh-header`}
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {accordionEntry.title}
              </Typography>
              <Typography
                sx={{ width: "10%", flexShrink: 0, color: "text.secondary" }}
              >
                {`#${accordionEntry.data.length}`}
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                {accordionEntry.subtitle}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {accordionEntry.data.map((it) => {
                  return (
                    <span>
                      {it}
                      <br />
                    </span>
                  );
                })}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}

export default function AdminPricesSync() {
  const [params] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [infoBoxOpen, setInfoBoxOpen] = useState(false);
  const [reportData, setReportData] = useState<ReportData>();
  return (
    <Box>
      <LoadingButton
        onClick={async () => {
          try {
            setLoading(true);
            const res = (await fetch(
              `${config.apiUrl}/sofa-woocommerce-import/prices`,
              {
                headers: {
                  "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({
                  adminKey: params.get("adminKey"),
                  region: (window as any).reeExternalConfig.pricesRegion,
                  pricesUrl: (window as any).reeExternalConfig.pricesUrl,
                }),
              }
            ).then((res) => res.json())) as ReportData;
            if (res.s !== 0) {
              throw Error("Syncing prices failed !");
            }
            setInfoBoxOpen(true);
            console.log("RES", res);
            setReportData(res);
            /*
         if (res.failedList.length !== 0) {
         let failedStr = "FAILED SYNC FOR PRODUCTS:\n";
         for (let failed of res.failedList) {
         failedStr += failed + "\n";
         }
         alert(failedStr);
         }
         */
          } catch (err) {
            alert(err);
          } finally {
            setLoading(false);
          }
        }}
        endIcon={<SyncIcon />}
        loading={loading}
        loadingPosition="end"
        variant="outlined"
      >
        <span>SYNC PRICES</span>
      </LoadingButton>
      <Dialog
        open={infoBoxOpen}
        onClose={() => setInfoBoxOpen(false)}
        scroll="paper"
        fullWidth={true}
        maxWidth={false}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Prices sync report</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            {reportData && <PricesSyncReport data={reportData} />}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInfoBoxOpen(false)}>Close report</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
