import { useState, useEffect } from "react";
import { Box, Typography, IconButton, Button } from "@mui/material";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import CloseIcon from "@mui/icons-material/Close";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ImageListChooser, { ImageListChooserSize } from "./ImageListChooser";
import { config } from "./Config";
// import { setResizeFunction, customizeObjectResize } from "./PixelStreaming";
import { isPixels } from "./Util";
import { WindowMaterialsData, WindowMaterial } from "./Windows/DataTypes";
import TextOptionsChooser from "./TextOptionsChooser";

/*
   function detailsResize(
windowWidth: number,
windowHeight: number,
setRootAlignRight: (val: boolean) => void
): void {
if (isPixels()) {
const root = document.getElementById("root");
if (root === null) {
console.error("Couldn't find root element");
} else {
root.setAttribute(
"style",
`
position: absolute;
top: 0;
right: 0;
width: 70px;
height: 70px;
margin: 0;
padding: 0;
`
);
}
} else {
// TODO
}
}*/

function filesServerUrl(): string {
  const url = config.filesServerOverride;
  if (url !== undefined) {
    return url;
  } else {
    let extWindow = window as any;
    return `https://${extWindow.reeSignals}/files`;
  }
}

function Sidebar(props: {
  data: any;
  setData: (data: any) => void;
  setIsDetail: (value: boolean) => void;
  materialsData: WindowMaterialsData | undefined;
}) {
  const [typeIndex, setTypeIndex] = useState<number>(0);
  const initialStyleIndexes: number[] = [];
  for (let i = 0; i < props.data.types.length; ++i) {
    initialStyleIndexes.push(0);
  }
  const [styleIndexes, setStyleIndexes] =
    useState<number[]>(initialStyleIndexes);
  const [curMaterial, setCurMaterial] = useState<number>(
    props.materialsData?.data[0].id ?? -1
  );
  let initialColors: { [key: number]: number } = {};
  if (props.materialsData !== undefined) {
    for (const material of props.materialsData.data) {
      initialColors[material.id] = material.colors[0].id;
    }
  }
  const [curColors, setCurColors] = useState<{ [key: number]: number }>(
    initialColors
  );
  const animations = props.data.types[typeIndex].animations;
  const details = props.data.types[typeIndex].details;
  const isAnimatable = animations !== undefined && animations.length > 0;
  const curColor = curColors[curMaterial] ?? -1;
  const curMaterialObj = props.materialsData?.data.find(
    (it) => it.id === curMaterial
  );
  return (
    <OverlayScrollbarsComponent
      style={{
        backgroundColor: "#fff",
        width: "400px",
        pointerEvents: "initial",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
      }}
      options={{
        overflowBehavior: {
          x: "hidden",
          y: "scroll",
        },
      }}
    >
      <IconButton
        sx={{
          pointerEvents: "initial",
          position: "absolute",
          top: 0,
          right: 0,
          margin: "10px 0 0 20px",
          padding: 0,
        }}
        onClick={() => {
          props.setData(null);
        }}
      >
        <CloseIcon
          sx={{
            width: "50px",
            height: "50px",
          }}
        />
      </IconButton>
      <Box
        sx={{
          margin: "25px",
          marginTop: "70px",
          bgcolor: "background.paper",
        }}
      >
        {/*
        <Typography
        sx={{
margin: "34px 0px 17px 60px",
fontFamily: "Barlow",
fontSize: "28px",
fontWeight: "800",
textAlign: "left",
color: "#414042",
}}
variant="h1"
>
PRIMA 9.2 - visoki fix:
</Typography>
<Typography
sx={{
margin: "0px 0px 50px 60px",
fontFamily: "Barlow",
fontSize: "14px",
fontWeight: "300",
textAlign: "left",
color: "#414042",
}}
>
Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
<br></br>
nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam.
<br></br>
Vgradna debelina podboja (mm): <b>78</b>
<br></br>
Debelina krila (mm): <b>78</b>
<br></br>
Vidna širina podboja s krilom prečno / spodaj (mm): <b>115/133</b>
<br></br>
<b>Uw</b>- toplotna prehodnost oken ( W/m2K): <b>0,80-1,5</b>
<br></br>
<b>Rw</b>- standardna zvočna izolativnost oken (dB):{" "}
<b>34 (-1; -5)</b>
</Typography>
*/}
        {props.data.types.length > 1 && (
          <ImageListChooser
            title="Izberi stil okna:"
            size={ImageListChooserSize.Big}
            selectedIndex={typeIndex}
            onSelected={(index: number) => {
              let extWindow = window as any;
              const event = {
                event: `select_type${isAnimatable ? "_animated" : ""}`,
                index: index,
                pointer: props.data.pointer,
              };
              if (extWindow.ue === undefined) {
                console.log("handling event via PIXELS", event);
                extWindow.emitUIInteraction(event);
              } else {
                console.log("handling event via direct call...");
                console.log(event);
                extWindow.ue.reepui.handleevent(JSON.stringify(event));
              }
              setTypeIndex(index);
            }}
            onInfoClick={(index: number) => {}}
            items={props.data.types.map(function (item: any) {
              const url = item.icon.replace(
                "Content/ReebaStaticFiles",
                filesServerUrl()
              );
              return {
                title: item.name,
                image: url,
                enableInfo: false,
              };
            })}
          />
        )}
        {curMaterialObj !== undefined && (
          <TextOptionsChooser<WindowMaterial>
            mainTitle={"Izberi material"}
            options={props.materialsData?.data ?? []}
            getTitle={(material) => material.translations[0].name}
            getId={(material) => material.id}
            selectedOption={curMaterialObj}
            setSelectedOption={(material) => {
              console.log("Changing material");
              setCurMaterial(material.id);
              let color: string | undefined;
              if (curColors[material.id] === undefined) {
                color = material.colors[0].external_id;
              } else {
                color = material.colors.find(
                  (it) => it.id === curColors[material.id]
                )?.external_id;
              }
              if (color === undefined) {
                console.error();
              } else {
                (window as any).emitUIInteraction({
                  event: `window_change_color`,
                  color: color,
                });
              }
            }}
            isPopup={false}
            columns={4}
          />
        )}
        <Typography
          sx={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1.3em",
            fontWeight: 600,
            color: "#000",
            marginTop: "0.5em",
            marginBottom: "0.5em",
          }}
        >
          Izberi barvo
        </Typography>
        <Box
          sx={{
            marginTop: "0",
            display: "grid",
            gridTemplateColumns: "repeat(8, 1fr)",
            gridTemplateRows: "1fr",
            gridColumnGap: "10px",
            gridRowGap: "10px",
          }}
        >
          {props.materialsData?.data
            .find((it) => it.id === curMaterial)
            ?.colors.map((color) => {
              const isSelected = color.id === curColor;
              const borderElement = {
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                borderRadius: "3px",
                content: '""',
              };
              return (
                <Button
                  key={color.id}
                  variant="outlined"
                  disabled={isSelected}
                  sx={{
                    minWidth: "inherit",
                    padding: 0,
                    width: "100%",
                    height: "100%",
                    border: "none",
                    "&.Mui-disabled": {
                      border: "none",
                    },
                    ...(isSelected
                      ? {
                          "::after": {
                            ...borderElement,
                            border: "solid 3px #000",
                          },
                          "::before": {
                            ...borderElement,
                            border: "solid 6px #fff",
                          },
                        }
                      : {
                          ":hover": {
                            border: "none",
                            opacity: 0.7,
                          },
                        }),
                  }}
                  onClick={() => {
                    console.log("Change color clicked");
                    setCurColors({
                      ...curColors,
                      [curMaterial]: color.id,
                    });
                    (window as any).emitUIInteraction({
                      event: `window_change_color`,
                      color: color.external_id,
                    });
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      borderRadius: "3px",
                      width: "100%",
                      // height: "100%",
                      // width: "calc(100% - 11px)",
                      // height: "calc(100% - 11px)",
                    }}
                    src={`${config.apiUrl}/assets/${color.icon}`}
                  />
                </Button>
              );
            })}
        </Box>
        {false && props.data.types[typeIndex].styles.length > 1 && (
          <ImageListChooser
            title="Izberi material in barvo:"
            size={ImageListChooserSize.Small}
            selectedIndex={styleIndexes[typeIndex]}
            onSelected={(index: number) => {
              let extWindow = window as any;
              const event = {
                event: `select_style${isAnimatable ? "_animated" : ""}`,
                index: index,
                pointer: props.data.pointer,
              };
              if (extWindow.ue === undefined) {
                console.log("handling event via PIXELS", event);
                extWindow.emitUIInteraction(event);
              } else {
                console.log("handling event via direct call...");
                console.log(event);
                extWindow.ue.reepui.handleevent(JSON.stringify(event));
              }
              setStyleIndexes((oldArr) => {
                const newArr = [...oldArr];
                newArr[typeIndex] = index;
                return newArr;
              });
            }}
            onInfoClick={(index: number) => {}}
            items={props.data.types[typeIndex].styles.map(function (item: any) {
              const url = item.icon.replace(
                "Content/ReebaStaticFiles",
                filesServerUrl()
              );
              return {
                title: item.name,
                image: url,
                enableInfo: false,
              };
            })}
          />
        )}
        {isAnimatable && (
          <TextOptionsChooser<{ title: string; id: number }>
            mainTitle={"Predogled odpiranja / zapiranja"}
            options={animations.map((item: any, index: number) => ({
              title: item.name == "Close" ? "Zapri" : "Odpri",
              id: index,
            }))}
            getTitle={(option) => option.title}
            getId={(option) => option.id}
            selectedOption={{ title: "none", id: -1 }}
            setSelectedOption={(option) => {
              const index = option.id;
              let extWindow = window as any;
              const event = {
                event: "trigger_animation",
                index: index,
                pointer: props.data.pointer,
              };
              if (extWindow.ue === undefined) {
                console.log("handling event via PIXELS", event);
                extWindow.emitUIInteraction(event);
              } else {
                console.log("handling event via direct call...");
                console.log(event);
                extWindow.ue.reepui.handleevent(JSON.stringify(event));
              }
            }}
            isPopup={false}
            columns={4}
          />
        )}
        {false && isAnimatable && (
          <ImageListChooser
            title="Predogled odpiranja / zapiranja:"
            size={ImageListChooserSize.Big}
            selectedIndex={-3}
            onSelected={(index: number) => {
              let extWindow = window as any;
              const event = {
                event: "trigger_animation",
                index: index,
                pointer: props.data.pointer,
              };
              if (extWindow.ue === undefined) {
                console.log("handling event via PIXELS", event);
                extWindow.emitUIInteraction(event);
              } else {
                console.log("handling event via direct call...");
                console.log(event);
                extWindow.ue.reepui.handleevent(JSON.stringify(event));
              }
            }}
            onInfoClick={(index: number) => {}}
            items={animations.map(function (item: any) {
              const url = item.icon.replace(
                "Content/ReebaStaticFiles",
                filesServerUrl()
              );
              return {
                title: item.name,
                image: url,
                enableInfo: false,
              };
            })}
          />
        )}
        {details !== undefined && details.length > 0 && (
          <TextOptionsChooser<{ title: string; id: number }>
            mainTitle={"Povečava prerezov:"}
            options={details.map((item: any, index: number) => ({
              title: "Odpri kotnik",
              id: index,
            }))}
            getTitle={(option) => option.title}
            getId={(option) => option.id}
            selectedOption={{ title: "none", id: -1 }}
            setSelectedOption={(option) => {
              let extWindow = window as any;
              const event = {
                event: `show_detail_3d${isAnimatable ? "_animated" : ""}`,
                index: option.id,
                pointer: props.data.pointer,
              };
              if (extWindow.ue === undefined) {
                console.log("handling event via PIXELS", event);
                extWindow.emitUIInteraction(event);
              } else {
                console.log("handling event via direct call...");
                console.log(event);
                extWindow.ue.reepui.handleevent(JSON.stringify(event));
              }
              props.setIsDetail(true);
              // setResizeFunction(detailsResize);
              const inst = document.getElementById("instructions");
              if (inst === null) {
                console.error();
                return;
              }
              inst.style.display = "none";
            }}
            isPopup={false}
            columns={2}
          />
        )}
      </Box>
    </OverlayScrollbarsComponent>
  );
}

function Detail3DView(props: { setIsDetail: (value: boolean) => void }) {
  return (
    <IconButton
      sx={{ pointerEvents: "initial", margin: "20px 20px 0 0", padding: 0 }}
      onClick={() => {
        let extWindow = window as any;
        const event = {
          event: `close_detail_3d`,
        };
        if (extWindow.ue === undefined) {
          console.log("handling event via PIXELS");
          extWindow.emitUIInteraction(event);
        } else {
          console.log("handling event via direct call...");
          console.log(event);
          extWindow.ue.reepui.handleevent(JSON.stringify(event));
        }
        props.setIsDetail(false);
        // setResizeFunction(customizeObjectResize);
        const inst = document.getElementById("instructions");
        if (inst === null) {
          console.error();
          return;
        }
        inst.style.display = "block";
      }}
    >
      <CloseIcon
        sx={{
          width: "50px",
          height: "50px",
        }}
      />
    </IconButton>
  );
}

export default function CustomizeObject(props: {
  data: any;
  setData: (data: any) => void;
  isDetail: boolean;
  setIsDetail: (value: boolean) => void;
  materialsData: WindowMaterialsData | undefined;
}) {
  if (props.isDetail) {
    return <Detail3DView setIsDetail={props.setIsDetail} />;
  } else {
    return (
      <Sidebar
        data={props.data}
        setData={props.setData}
        setIsDetail={props.setIsDetail}
        materialsData={props.materialsData}
      />
    );
  }
}
